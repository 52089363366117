import { createNamespacedHelpers } from 'vuex';
import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper';
import { Users, Getters, Mutations, Actions } from '@/models/User.model';

// Inital state
const state: Users = {
  loggedInUser: {
    loggedIn: false,
    displayName: '',
    registrationStatusId: 0,
    lastLogin: '',
  },
  users: [],
  userTypes: [],
};

// Gets the values of our state usually called from components
const getters: DefineGetters<Getters, Users> = {
  loggedInUser: (state) => state.loggedInUser,
  users: (state) => state.users,
  userTypes: (state) => state.userTypes,
};

// Sets values or changes to state. Must be called via an action.
const mutations: DefineMutations<Mutations, Users> = {
  resetLoggedInUser(state) {
    state.loggedInUser = {
      loggedIn: false,
      displayName: '',
      registrationStatusId: 0,
      lastLogin: '',
    };
  },

  resetLoggedInProfile() {
    state.loggedInUser = {
      loggedIn: state.loggedInUser.loggedIn,
      displayName: state.loggedInUser.displayName,
      registrationStatusId: state.loggedInUser.registrationStatusId,
      lastLogin: state.loggedInUser.lastLogin,
      // don't set profile as we are resetting that (but we want to keep the info above the same)
    };
  },
  
  setLoggedInUser(state, { loggedInUser }) {
    state.loggedInUser = loggedInUser;
  },

  setLoggedInProfile(state, { profile }) {
    state.loggedInUser.profile = profile;
  },

  setUsers(state, { users }) {
    state.users = users;
  },

  setUserTypes(state, { userTypes }) {
    state.userTypes = userTypes;
  },
};

// Async functions that will contain the logic / service calls and commit mutations to state
const actions: DefineActions<Actions, Users, Mutations, Getters> = {
  resetLoggedInUser({ commit }, payload) {
    commit('resetLoggedInUser', payload);
  },

  resetLoggedInProfile({ commit }, payload) {
    commit('resetLoggedInProfile', payload);
  },

  setLoggedInUser({ commit }, payload) {
    commit('setLoggedInUser', payload);
  },

  setLoggedInProfile({ commit }, payload) {
    commit('setLoggedInProfile', payload);
  },

  setUsers({ commit }, payload) {
    commit('setUsers', payload);
  },

  setUserTypes({ commit }, payload) {
    commit('setUserTypes', payload);
  },
};

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers<Users, Getters, Mutations, Actions>('UserModule');

export const UserModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
