import { createNamespacedHelpers, Commit } from 'vuex';
import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper';
import { MockAPI, Getters, Mutations, Actions } from '@/models/MockAPI.model';
import Axios from 'axios';

// Inital state
const state: MockAPI = {
  shootLocationTypes: [],
};

// Gets the values of our state usually called from components
const getters: DefineGetters<Getters, MockAPI> = {
  shootLocationTypes: (state) => state.shootLocationTypes,
};

// TODO: Change mutation names to uppercase ( Standards )
// Sets values or changes to state. Must be called via an action.
const mutations: DefineMutations<Mutations, MockAPI> = {
  setShootLocationTypes(state, { shootLocationTypes }) {
    state.shootLocationTypes = shootLocationTypes;
  },
};

// Async functions that will contain the logic / service calls and commit mutations to state
const actions: DefineActions<Actions, MockAPI, Mutations, Getters> = {
  setShootLocationTypes({ commit }, payload) {
    commit('setShootLocationTypes', payload);
  },
};

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers<MockAPI, Getters, Mutations, Actions>('MockAPIModule');

export const MockAPIModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
