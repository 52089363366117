/**
 *  This is our Axios configuration and start of the connection.
 *  It checks local storage to see if there is a JWT token present for future API calls.
 *  Our backend applications use JWT authentication and most calls will require this to be set.
 *
 *  We can also set which headers are required. This will feed into our other api calls which are
 *  separated into different files for easy managment and readiibility
 */

import AppService from '@/services/app';
import axios from 'axios';
import Store from '@/store/index';


axios.defaults.withCredentials = true;

export default () => {

  const thisAxios = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });

  // check for any 401 - Unauthorized responses and kick to login url
  thisAxios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    // tslint:disable-next-line:no-console
    //console.log(`api.ts - 33 - error`, error);
    if ((error.response && error.response.status && 401 === error.response.status) ||
      (error.message && error.message === 'Request failed with status code 401')) {  
      
      // if this is a brand new window / session, the first thing we will do is call AuthService.checkAuth()
      // in the router beforeEnter. If this is the FIRST call, 
      // we don't want to show them the session timeout error, 
      // we just want to redirect them to login
      const originalRequest = error.config;      
      if (originalRequest.url !== '/me') {
        AppService.errorHandler(Store.getters['app/messages'].sessionTimeout);
      }
      // tslint:disable-next-line:no-console
      //console.log(`api.ts - 46 - ${new Date().toISOString().split('.')[0].replace('T', ' ')}`, 'Got a 401, kicking back to Login'); 

      // on LIVE
      window.location.href = Store.getters['app/urls'].login;
      return Promise.reject(); // dont send error back because we want the sessionTimeout error to show

    } else {
      // tslint:disable-next-line:no-console
      //console.log(`api.ts - 54 - ${new Date().toISOString().split('.')[0].replace('T', ' ')}`, 'Got an error, kicking back to Login'); 
      // tslint:disable-next-line:no-console
      //console.log(`api.ts - 56 - `, error);

      window.location.href = Store.getters['app/urls'].login;
      return Promise.reject(error); // dont send error back because we want the sessionTimeout error to show
    }
  });

  return thisAxios;
};
