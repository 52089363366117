import { createNamespacedHelpers } from 'vuex';
import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper';
import { AppData, Getters, Mutations, Actions } from '@/models/App.model';

// --- Third Party ---
import dayjs from 'dayjs';

// Inital state
const state: AppData = {

  snackbar: {
    show: false,
    text: '',
    color: 'error',
    mode: '',
    timeout: Number(process.env.VUE_APP_SNACKBAR_TIMEOUT),
    x: '',
    y: 'top',
    icon: '',
    closeIcon: 'mdi-close-circle-outline',
  },

  drawer: null,
  mini: false,
  
  categories: [],
  subCategories: [],
  registrationTypes: [],
  locationTypes: [],
  regions: [],
};

// Gets the values of our state usually called from components
const getters: DefineGetters<Getters, AppData> = {

  urls: (state) => {
    return {
      login: process.env.VUE_APP_URL_LOGIN,
    };
  },

  icons: (state) => {
    return {
      // --- generic ---
        // -- informational
        date: 'mdi-calendar-outline',
        email: 'mdi-email',
        error: 'mdi-alert',
        favourite: 'mdi-star',
        info: 'mdi-information',
        inProgress: 'mdi-progress-clock',
        no: 'mdi-close-thick',
        password: 'mdi-lock',
        pdf: 'mdi-file-pdf-box',
        phone: 'mdi-phone-outline',
        profile: 'mdi-account',
        settings: 'mdi-cog',
        success: 'mdi-check-circle',
        telegram: 'mdi-telegram',
        time: 'mdi-clock',
        username: 'mdi-account',
        warning: 'mdi-bell',
        web: 'mdi-web',
        whatsapp: 'mdi-whatsapp',
        yes: 'mdi-check-bold',
        // --- actions ---
        activate: 'mdi-play',
        add: 'mdi-plus-box',
        close: 'mdi-close-circle-outline',
        contract: 'mdi-menu-up', 
        copy: 'mdi-content-copy', 
        delete: 'mdi-delete',
        download: 'mdi-download',
        edit: 'mdi-pencil',
        expand: 'mdi-menu-down', 
        filter: 'mdi-filter-variant',
        filterEquipment: 'mdi-filter',
        login: 'mdi-fingerprint',
        next: 'mdi-chevron-right',
        previous: 'mdi-chevron-left',
        print: 'mdi-printer',
        refresh: 'mdi-refresh-circle',
        register: 'mdi-account-plus',
        search: 'mdi-magnify',
        suspend: 'mdi-pause',
        upload: 'mdi-paperclip',
        view: 'mdi-eye',
        
      // --- app specific ---
        // --- informational ---
        adminUser: 'mdi-shield-account',
        staffUser: 'mdi-account',
        newRegistration: 'mdi-new-box',
        emailVerified: 'mdi-email-check',
        //dataSaved: 'mdi-account-check',
        //insuranceSaved: 'mdi-file-document-check',
        userAwaitingApproval: 'mdi-progress-question',
        approved: 'mdi-check-decagram',
        profileEdited: 'mdi-progress-pencil',
        verificationAlert: 'mdi-shield-lock-outline',
        emailOutline: 'mdi-email-outline',        
        profilePlaceholder: 'mdi-account-circle',
        shootType: 'mdi-cog',
        shootDate: 'mdi-calendar-month',
        bookingNotice: 'mdi-hours-24',
        gearCheck: 'mdi-hammer-wrench',
        confirmedBooking: 'mdi-lock',
        pencil: 'mdi-lead-pencil',
        location: 'mdi-map-marker',
        missingVATNumber: 'mdi-alert-decagram',
        // --- actions --- 
        addCircle: 'mdi-plus-circle',
        downloadCircle: 'mdi-download-circle',
        addItem: 'mdi-plus',
        // --- nav ---
        shoots: 'mdi-movie-open',
        users: 'mdi-account-group',
        // --- profile nav ---
        // --- additional ---        
    };
  },

  messages: (state) => {
    return {
      couldNotConnect: 'Error: Please contact Eazigear for assistance.',
      sessionTimeout: 'Your session has timed out, please login to continue.',
    };
  },

  snackbar: (state) => state.snackbar,
  drawer: (state) => state.drawer,
  mini: (state) => state.mini,
  categories: (state) => state.categories,
  subCategories: (state) => state.subCategories,
  registrationTypes: (state) => state.registrationTypes,
  locationTypes: (state) => state.locationTypes,
  regions: (state) => state.regions,

  infoDialogs: (state) => {
    return {
      userType: {
        title: 'What is the difference between Administrators and Staff?',
        content: 'Administrators have full control over a profile, including the ability to sign up staff members, '
          + 'who are able to upload equipment and information and manage daily tasks. Signing up staff is free',
      },
      registrationType: {
        title: 'Should I register on behalf of myself or a company?',
        content: 'If you have a business registered and would like to have tax invoices generated, we advise to select \'company\'. '
          + 'This will also give you the ability to sign on staff members to assist you with uploading equipment and day to day '
          + 'running of your profile. '
          + 'If it is just you and none of the above applies, simply sign up as yourself for a more streamlined experience.',
      },
      companyRegistrationNumber: {
        title: 'Why do we need your Company\'s Registration Document?',
        content: 'Eazigear is a safe space, and we are committed to keeping it that way. '
          + 'In order to do so, we require that all businesses provide their Company\'s Registration Document upon sign up, '
          + 'which forms part of our verification process.',
      },
      vatNumber: {
        title: 'Why do we need your Company\'s VAT Number?',
        content: 'Our billing system generates tax invoices, so in order to remain compliant with SARS we require your VAT number.',
      },
      companyRegistrationAndVATNumber: {
        title: 'Why do we need your Company\'s Registration Document & VAT Number?',
        content: 'We need your Company\'s registration document so that we can verify your information, '
          + 'we need your tax number for billing purposes.',
      },
      cellphone: {
        title: 'Why do we need your Cellphone Number?',
        content: 'We need your cellphone number as a primary contact in order to communicate with you quickly and efficiently.',
      },
      contactNumber: {
        title: 'Why do we need a Contact Number?',
        content: 'We need a contact number for your Company as a primary contact in order to communicate with you quickly and efficiently.',
      },
      address: {
        title: 'Why do we need your Address?',
        //content: 'We need to know your address for efficient collection and delivery of orders if you opt to make use of our collection services. We also need your address for billing purposes',
        content: 'We need to know your address for for billing and verification purposes',
      },
      companyAddress: {
        title: 'Why do we need your Company\'s Address?',
        //content: 'We need to know your Company\'s address for efficient collection and delivery of orders if you opt to make use of our collection services, and for billing purposes',
        content: 'We need to know your Company\'s address for for billing and verification purposes',
      },
      idNumber: {
        title: 'Why do we need a copy of your ID?',
        content: 'We need a copy of your ID in order to verify your profile.',
      },
      directorIdNumber: {
        title: 'Why do we need a copy of the Company Director\'s ID?',
        content: 'We need a copy of the Company Director\'s ID in order to verify your business profile.',
      },
    };
  },

  // https://codeburst.io/vuex-getters-are-great-but-dont-overuse-them-9c946689b414
  // from what I gather, in order to make a getter update itself, it needs to be parameterised
  // this was not working previously using getters on the actual vue files, even when they were
  // calling a method in AppService. They would not refresh on each call.
  // because we need this to be a generic global method, I have written it here as a getter.
  autoId: (state) => (random: string): string => {
    let autoId: string = ''; 
    const dict: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; 
    
    for (let i = 0; i < 12; i++) { 
      autoId += dict.charAt(Math.floor(Math.random() * dict.length)); 
    } 

    return autoId;
  },

  maxWidthButton: (state) => (currentBreakpoint: string, maxWidthBreakpoints: string[]): string => {
    let returnValue = '';
    for (const breakpoint of maxWidthBreakpoints) {
      if (currentBreakpoint === breakpoint) {
        returnValue = '100%';
        break;
      }
    }

    return returnValue;
  },

  formatCurrency: (state) => (x: number): string  => {
    return 'R ' + x.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  totalBookingDays: (state) => (startDate: string, endDate: string): number  => {
    return dayjs(endDate).diff(startDate, 'days') + 1;
  },

  formattedDateRange: (state) => (startDate: string, endDate: string, withTotalDays: boolean): string  => {
    if (startDate === endDate && endDate !== '') {
      return dayjs(startDate).format('DD MMM YYYY') + (withTotalDays ? ' (1d)' : '');
    } else {
      return dayjs(startDate).format('DD MMM YYYY') 
        + ' - ' + dayjs(endDate).format('DD MMM YYYY') 
        + (withTotalDays ? ' (' + (dayjs(endDate).diff(startDate, 'days') + 1) + 'd)' : '');
    }
  },
};

// TODO: Change mutation names to uppercase ( Standards )
// Sets values or changes to state. Must be called via an action.
const mutations: DefineMutations<Mutations, AppData> = {

  snackbar(state, { show, text, color, icon }) {
    state.snackbar.show = show;
    state.snackbar.text = text;
    state.snackbar.color = color;
    state.snackbar.icon = icon;
  },

  snackbarWithCustomTimeout(state, { show, text, color, icon, timeout }) {
    state.snackbar.show = show;
    state.snackbar.text = text;
    state.snackbar.color = color;
    state.snackbar.icon = icon;
    state.snackbar.timeout = timeout;
  },

  setDrawer(state, payload) {
    state.drawer = payload;
  },

  setMini(state, payload) {
    state.mini = (payload as unknown) as boolean;
  },

  setCategories(state, { categories }) {
    state.categories = categories;
  },

  setSubCategories(state, { subCategories }) {
    state.subCategories = subCategories;
  },

  setRegistrationTypes(state, { registrationTypes }) {
    state.registrationTypes = registrationTypes;
  },

  setLocationTypes(state, { locationTypes }) {
    state.locationTypes = locationTypes;
  },

  setRegions(state, { regions }) {
    state.regions = regions;
  },

};

// Async functions that will contain the logic / service calls and commit mutations to state
const actions: DefineActions<Actions, AppData, Mutations, Getters> = {

  snackbar({ commit }, payload) {
    commit('snackbar', payload);
  },

  snackbarWithCustomTimeout({ commit }, payload) {
    commit('snackbarWithCustomTimeout', payload);
  },

  setDrawer({ commit }, payload) {
    commit('setDrawer', payload);
  },

  setMini({ commit }, payload) {
    commit('setMini', payload);
  },

  setCategories({ commit }, payload) {
    commit('setCategories', payload);
  },

  setSubCategories({ commit }, payload) {
    commit('setSubCategories', payload);
  },

  setRegistrationTypes({ commit }, payload) {
    commit('setRegistrationTypes', payload);
  },

  setLocationTypes({ commit }, payload) {
    commit('setLocationTypes', payload);
  },

  setRegions({ commit }, payload) {
    commit('setRegions', payload);
  },

};

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers<AppData, Getters, Mutations, Actions>(
  'AppModule',
);

export const AppModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
