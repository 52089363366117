import Vue from 'vue';
import vuetify from './plugins/vuetify';
import './plugins';

import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@/assets/scss/_global.scss';

import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

Vue.component('VueSlickCarousel', VueSlickCarousel);

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');

// tslint:disable-next-line:no-console
console.log(`main.ts - 20 - ${new Date().toISOString().split('.')[0].replace('T', ' ')}`, 'I am running in LIVE mode: ' + process.env.VUE_APP_ISLIVE);
if ('NO' === process.env.VUE_APP_ISLIVE && 'YES' !== process.env.VUE_APP_DOCKER) {  
  // https://stackoverflow.com/questions/46023926/vue-js-webpack-using-some-imports-only-in-development-mode
  // In case you make an conditional import (using JS if statement and checking env variables), you can't use import statement. I've used require.
  // tslint:disable-next-line
  const devMode = require('@/services/local');
}

