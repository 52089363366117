// Vue
import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import { AppModule } from './modules/app';
import { AuthModule } from './modules/auth';
//import { EquipmentModule } from './modules/equipment';
import { ShootModule } from './modules/shoot';
import { UserModule } from './modules/user';

// --- MOCK API - Do Not Remove ---
import { MockAPIModule } from './modules/mockapi';

Vue.use(Vuex);

const store = new Vuex.Store<any>({
  modules: {
    app: AppModule,
    auth: AuthModule,
    //equipment: EquipmentModule,
    shoots: ShootModule,
    users: UserModule,
    // --- MOCK API - Do Not Remove ---
    mockAPI: MockAPIModule,
  },
});

export default store;
