import { createNamespacedHelpers, Commit } from 'vuex';
import { DefineActions, DefineGetters, DefineMutations } from 'vuex-type-helper';
import { Shoots, Getters, Mutations, Actions } from '@/models/Shoot.model';

// Inital state
const state: Shoots = {
  shootTypes: [],
  shoots: [],
};

// Gets the values of our state usually called from components
const getters: DefineGetters<Getters, Shoots> = {
  shootTypes: (state) => state.shootTypes,
  shoots: (state) => state.shoots,
};

// TODO: Change mutation names to uppercase ( Standards )
// Sets values or changes to state. Must be called via an action.
const mutations: DefineMutations<Mutations, Shoots> = {
  setShootTypes(state, { shootTypes }) {
    state.shootTypes = shootTypes;
  },

  setShoots(state, { shoots }) {
    state.shoots = shoots;
  },
};

// Async functions that will contain the logic / service calls and commit mutations to state
const actions: DefineActions<Actions, Shoots, Mutations, Getters> = {
  setShootTypes({ commit }, payload) {
    commit('setShootTypes', payload);
  },

  setShoots({ commit }, payload) {
    commit('setShoots', payload);
  },
};

export const {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
} = createNamespacedHelpers<Shoots, Getters, Mutations, Actions>('ShootModule');

export const ShootModule = {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
};
