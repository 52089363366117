/**
 *   This is a service for a specific store module.
 *   We separate our files for easy managment and readibility.
 *   We export this as a service to be used in other areas of the application.
 */

import Api from '@/services/api';
import Store from '@/store/index';

export default {

  async logSupportDebug(message: string) {
    if ('NO' === process.env.VUE_APP_ISLIVE) {
      // tslint:disable-next-line:no-console
      console.log(`${new Date().toISOString().split('.')[0].replace('T', ' ')} - ` + message);
    }
  },

  async errorHandler(message: string) {
    Store.dispatch('app/snackbar', {
      show: true,
      text: message,
      color: 'error',
      icon: Store.getters['app/icons'].error,
    });
  },

  async successHandler(message: string) {
    Store.dispatch('app/snackbar', {
      show: true,
      text: message,
      color: 'success',
      icon: Store.getters['app/icons'].success,
    });
  },

  async successHandlerWithCustomTimeout(message: string, timeout: number) {
    Store.dispatch('app/snackbarWithCustomTimeout', {
      show: true,
      text: message,
      color: 'success',
      icon: Store.getters['app/icons'].success,
      timeout: timeout,
    });
  },

  async listRegistrationTypes(refreshFromAPI: boolean) {
    // check if we need to force an API refresh or have already fetched this data and it is in the Store
    if (refreshFromAPI || Store.getters['app/registrationTypes'].length < 1) {
      try {
        const response = await Api().get('/app/list_registration_types');
        
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            Store.dispatch('app/setRegistrationTypes', {
              registrationTypes: [],
            });
            return response.data.message;
          } else {
            Store.dispatch('app/setRegistrationTypes', {
              registrationTypes: response.data,
            });
            return '';
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        Store.dispatch('app/setRegistrationTypes', {
          registrationTypes: [],
        });
        if (error.response) {
          return error.response.statusText;
        } else {
          this.logSupportDebug('app.ts - listRegistrationTypes - 77 - ' + error);
          return Store.getters['app/messages'].couldNotConnect;
        }
      } 
    } else {
      // this data is ready to access in the Store
      return '';
    }
  },

  async listCategories(refreshFromAPI: boolean) {
    // check if we need to force an API refresh or have already fetched this data and it is in the Store
    if (refreshFromAPI || Store.getters['app/categories'].length < 1) {
      try {
        const response = await Api().get('/app/list_categories');
        
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            Store.dispatch('app/setCategories', {
              categories: [],
            });
            return response.data.message;
          } else {
            Store.dispatch('app/setCategories', {
              categories: response.data,
            });
            return '';
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        Store.dispatch('app/setCategories', {
          categories: [],
        });

        if (error.response) {
          return error.response.statusText;
        } else {
          this.logSupportDebug('app.ts - listCategories - 78 - ' + error);
          return Store.getters['app/messages'].couldNotConnect;
        }
      } 
    } else {
      // this data is ready to access in the Store
      return '';
    }
  },

  async listSubCategories(refreshFromAPI: boolean) {
    // check if we need to force an API refresh or have already fetched this data and it is in the Store
    if (refreshFromAPI || Store.getters['app/subCategories'].length < 1) {
      try {
        const response = await Api().get('/app/list_sub_categories');
        
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            Store.dispatch('app/setSubCategories', {
              subCategories: [],
            });
            return response.data.message;
          } else {
            Store.dispatch('app/setSubCategories', {
              subCategories: response.data,
            });
            return '';
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        Store.dispatch('app/setSubCategories', {
          subCategories: [],
        });

        if (error.response) {
          return error.response.statusText;
        } else {
          this.logSupportDebug('app.ts - listSubCategories - 119 - ' + error);
          return Store.getters['app/messages'].couldNotConnect;
        }
      } 
    } else {
      // this data is ready to access in the Store
      return '';
    }
  },

  async listLocationTypes(refreshFromAPI: boolean) {
    // check if we need to force an API refresh or have already fetched this data and it is in the Store
    if (refreshFromAPI || Store.getters['app/locationTypes'].length < 1) {
      try {
        const response = await Api().get('/app/list_location_types');
        
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            Store.dispatch('app/setLocationTypes', {
              locationTypes: [],
            });
            return response.data.message;
          } else {
            Store.dispatch('app/setLocationTypes', {
              locationTypes: response.data,
            });
            return '';
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        Store.dispatch('app/setLocationTypes', {
          locationTypes: [],
        });

        if (error.response) {
          return error.response.statusText;
        } else {
          this.logSupportDebug('app.ts - listLocationTypes - 160 - ' + error);
          return Store.getters['app/messages'].couldNotConnect;
        }
      } 
    } else {
      // this data is ready to access in the Store
      return '';
    }
  },

  async listRegions(refreshFromAPI: boolean) {
    // check if we need to force an API refresh or have already fetched this data and it is in the Store
    if (refreshFromAPI || Store.getters['app/regions'].length < 1) {
      try {
        const response = await Api().get('/app/list_regions');
        
        if (response && response.data) {
          if (response.data.result && response.data.result === 'false') {
            Store.dispatch('app/setRegions', {
              regions: [],
            });
            return response.data.message;
          } else {
            Store.dispatch('app/setRegions', {
              regions: response.data,
            });
            return '';
          }
        } else {
          // response is undefined or has no data field - SHOULD NEVER HAPPEN!
          throw new Error('response: ' + JSON.stringify(response));
        }

      } catch (error) {
        Store.dispatch('app/setRegions', {
          regions: [],
        });
        if (error.response) {
          return error.response.statusText;
        } else {
          this.logSupportDebug('app.ts - listRegions - 200 - ' + error);
          return Store.getters['app/messages'].couldNotConnect;
        }
      } 
    } else {
      // this data is ready to access in the Store
      return '';
    }
  },

};
