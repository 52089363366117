// Imports
import Vue from 'vue';
import Router from 'vue-router';
import AuthService from '@/services/auth';
import Store from '@/store';

Vue.use(Router);

// create object router with the valid initialization
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash };
    }
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/logout',
      component: () => import('@/layouts/page/Index.vue'),
      children: [
        {
          name: 'Logout',
          path: '',
          component: () => import('@/views/Logout.vue'),
        },
      ],
    },
    {
      path: '/legal',
      component: () => import('@/layouts/pdf/Index.vue'),
      children: [
        {
          name: 'Privacy Policy',
          path: '/privacy-policy',
          component: () => import('@/views/Legal_Pdf.vue'),
        },
        {
          name: 'Terms of Service',
          path: '/terms-of-service',
          component: () => import('@/views/Legal_Pdf.vue'),
        },
        {
          name: 'Trust & Safety',
          path: '/trust-and-safety',
          component: () => import('@/views/Legal_Pdf.vue'),
        },
      ],
    },
    {
      path: '/complete-registration',
      beforeEnter: (to, from, next) => {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            // check to see if the user is logged in
            // just call the checkAuth method. It will handle things if User is not Authed.  
            AuthService.getLoggedInUser().then((response) => {
              const loggedInUser = Store.getters['users/loggedInUser'];
              if (loggedInUser.registrationStatusId < 5 ) {
                next();
              } else if (loggedInUser.registrationStatusId === 5 ) {
                next('/awaiting-approval');
              } else {
                next('');
              }
            });
        } else {
          next();
        }
      },
      component: () => import('@/layouts/page/Index.vue'),
      children: [
        {
          name: 'Complete Registration',
          path: '',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/CompleteRegistration.vue'),
        },
      ],
    },
    {
      path: '/awaiting-approval',
      beforeEnter: (to, from, next) => {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            // check to see if the user is logged in
            // just call the checkAuth method. It will handle things if User is not Authed.  
            AuthService.getLoggedInUser().then((response) => {
              const loggedInUser = Store.getters['users/loggedInUser'];
              if (loggedInUser.registrationStatusId < 5 ) {
                next('/complete-registration');
              } else if (loggedInUser.registrationStatusId === 5 ) {
                next();
              } else {
                next('');
              }
            });
        } else {
          next();
        }
      },
      component: () => import('@/layouts/page/Index.vue'),
      children: [
        {
          name: 'Awaiting Approval',
          path: '',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/AwaitingApproval.vue'),
        },
      ],
    },
    {
      path: '/edit',
      beforeEnter: (to, from, next) => {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            // check to see if the user is logged in
            // just call the checkAuth method. It will handle things if User is not Authed.  
            AuthService.getLoggedInUser().then((response) => {
              const loggedInUser = Store.getters['users/loggedInUser'];
              if (loggedInUser.registrationStatusId === 5 ) {
                next();
              } else {
                next('');
              }
            });
        } else {
          next();
        }
      },
      component: () => import('@/layouts/page/Index.vue'),
      children: [
        {
          name: 'Edit Registration',
          path: '',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/CompleteRegistration.vue'),
        },
      ],
    },
    {
      path: '',
      beforeEnter: (to, from, next) => {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            // check to see if the user is logged in
            // just call the checkAuth method. It will handle things if User is not Authed.  
            AuthService.getLoggedInUser().then((response) => {
              const loggedInUser = Store.getters['users/loggedInUser'];
              if (loggedInUser.registrationStatusId < 5 ) {
                next('/complete-registration');
              } else if (loggedInUser.registrationStatusId === 5 ) {
                next('/awaiting-approval');
              } else {
                next();
              }
            });
        } else {
          next();
        }
      },
      component: () => import('@/layouts/base/Index.vue'),
      children: [    
        // overview
        {
          name: 'Home',
          path: '',
          redirect: '/shoots',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/Home.vue'),
        },
        
        // profile
        {
          name: 'My Profile',
          path: '/profile',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/UserProfile.vue'),
        },
        // user management - only company registrations have access
        {
          name: 'User Management',
          path: '/users',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/User_Listing.vue'),
        }, 
        // shoot listing
        {
          name: 'My Shoots',
          path: '/shoots',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/Shoot_Listing.vue'),
        },
        
        // shoot Detail
        {
          name: 'Shoot Detail',
          path: '/shoot/:id',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/Shoot_View.vue'),
          props: true,
        },

        // Respond to Booking Request
        {
          name: 'Booking Response',
          path: '/respond/:id',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/BookingResponse.vue'),
          props: true,
        },

        // Shoot Equipment listing
        {
          name: 'Shoot Equipment Listing',
          path: '/shoot/:shootId/equipment/',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/Equipment_Listing.vue'),
          props: true,
        },

        // Shoot Equipment listing with existing facets
        {
          name: 'Shoot Equipment Listing with Facets',
          path: '/shoot/:shootId/equipment/*',
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/Equipment_Listing.vue'),
          props: true,
        },

        // equipment detail (this needs to be before the wildcard listing route for higher priority)
        {
          name: 'View Shoot Equipment Detail',
          path: '/shoot/:shootId/add/equipment/:equipmentId',
          props: true,
          meta: {
            requiresAuth: true,
          },
          component: () => import('@/views/Equipment_View.vue'),
        },

        
      ],
    },
  ],
});

// export router as default
export default router;
