/**
 *   This is a service for a specific store module which uses the api.ts Axios instance as a base.
 *   We separate our files for easy managment and readibility.
 *   We export this as a service to be used in other areas of the application.
 */

import Api from '@/services/api';
import AppService from '@/services/app';
import Store from '@/store/index';

export default {

  async getLoggedInUser() {
    try {
      const response = await Api().get('/auth');
      
      if (response && response.data) {
        if (response.data.result && response.data.result === 'false') {
          Store.dispatch('users/resetLoggedInUser', {});
          return response.data.message;
        } else {     
          Store.dispatch('users/setLoggedInUser', {
            loggedInUser: {
              loggedIn: true,
              displayName: response.data.displayName,
              registrationStatusId: response.data.registrationStatusId,
              lastLogin: response.data.lastLogin,
            },
          });
          return '';
        }
      } else {
        // response is undefined or has no data field - SHOULD NEVER HAPPEN!
        throw new Error('response: ' + JSON.stringify(response));
      }

    } catch (error) {
      Store.dispatch('users/resetLoggedInUser', {});
      if (error.response) {
        return error.response.statusText;
      } else {
        AppService.logSupportDebug('auth.ts - getLoggedInUser - 42 - ' + error);
        return Store.getters['app/messages'].couldNotConnect;
      }
    }
  },

  async getProfile() {
    try {
      const response = await Api().get('/auth/profile');
      
      if (response && response.data) {
        if (response.data.result && response.data.result === 'false') {
          Store.dispatch('users/resetLoggedInProfile', {});
          return response.data.message;
        } else {
          Store.dispatch('users/setLoggedInProfile', {
            profile: {
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              email: response.data.email,
              registrationTypeId: response.data.registrationTypeId,
              registrationType: response.data.registrationType,
              userTypeId: response.data.userTypeId,
              userType: response.data.userType,
              companyName: response.data.companyName,
              vatNumber: response.data.vatNumber,
              companyRegistrationNumber: response.data.companyRegistrationNumber,
              companyRegistrationFilename: response.data.companyRegistrationFilename,
              avatarFilename: response.data.avatarFilename,
              cellphoneCountryRegion: response.data.cellphoneCountryRegion,
              cellphoneCountryDialCode: response.data.cellphoneCountryDialCode,
              cellphoneNumber: response.data.cellphoneNumber,
              companyContactCountryRegion: response.data.companyContactCountryRegion,
              companyContactCountryDialCode: response.data.companyContactCountryDialCode,
              companyContactNumber: response.data.companyContactNumber,
              addressLine1: response.data.addressLine1,
              addressLine2: response.data.addressLine2,
              addressLine3: response.data.addressLine3,
              city: response.data.city,
              postalCode: response.data.postalCode,
              country: response.data.country,
              regionId: response.data.regionId,
              region: response.data.region,
              addressFilename: response.data.addressFilename,
              idNumber: response.data.idNumber,
              idFilename: response.data.idFilename,
              directorIdNumber: response.data.directorIdNumber,
              directorIdFilename: response.data.directorIdFilename,
              invitedById: response.data.invitedById,
              invitedBy: response.data.invitedBy,
              hasUpcomingShoots: response.data.hasUpcomingShoots,
            },
          });
          return '';
        }
      } else {
        // response is undefined or has no data field - SHOULD NEVER HAPPEN!
        throw new Error('response: ' + JSON.stringify(response));
      }

    } catch (error) {
      Store.dispatch('users/resetLoggedInProfile', {});
      if (error.response) {
        return error.response.statusText;
      } else {
        AppService.logSupportDebug('auth.ts - getProfile - 91 - ' + error);
        return Store.getters['app/messages'].couldNotConnect;
      }
    }
  },

  createProducer( formData: FormData ) {
    return Api().post('/auth/create_personal', formData);
  },

  updateProducer( formData: FormData ) {
    return Api().post('/auth/update_personal', formData);
  },

  updateProfile( formData: FormData ) {
    return Api().post('/auth/update_profile', formData);
  },

  updateAvatar( formData: FormData ) {
    return Api().post('/auth/update_profile_picture', formData);
  },

  resetPassword() {
    return Api().post('/auth/reset_password');
  },

  delete() {
    return Api().post('/auth/delete');
  },

  logout() {
    // TODO empty the store
    Store.dispatch('users/resetLoggedInUser', {});
    
    Api().post('/auth/logout').then((response) => {
      // redirect the user back to Auth Portal
      AppService.successHandler(response.data.message);
      window.location.href = Store.getters['app/urls'].login;
    });
  },

};
